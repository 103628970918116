import "./Finished.css"
import Button from './Button'
import GoHomeTimer from "./GoHomeTimer";
import { Device, Screen, usePodContext } from "../PodContext";
import { useState } from "react";
import CN from 'classnames';
import { useNavigate } from "react-router-dom";
import mixpanel from "../../mixpanel/mixpanel";

const Finished = () => {
  const navigate = useNavigate()
  const { device } = usePodContext()

  const [email, setEmail] = useState('')
  const [signedUp, setSignedUp] = useState(false)

  const signup = () => {
    mixpanel.track('Signup', { email })
    fetch('https://cabana-prod-service.azurewebsites.net/cabana/public/pod/sign-up', {
      method: 'POST',
      body: email
    }).then(response => setSignedUp(response.ok))
  }

  const signupOnEnter = (e: any) => {
    if(e.keyCode === 13) signup()
  }

  const goHome = () => navigate(Screen.HOME)
  const goAgain = () => {
    navigate(Screen.SELECT_SCENE)
  }

  return (
    <>
      <GoHomeTimer />
      <div className="vr-finished-container">
        <img src={process.env.PUBLIC_URL + '/assets/logos/logo-white.svg'} alt="logo" className="vr-home-logo" />

        <div className="vr-finished-section" style={{ display: device === Device.HEADSET ? 'none' : ''}}>
          <h1 className="vr-finished-title">
              {signedUp
              ? <>Thanks for signing up.<br />Check your email to get started!</>
              : <>Want more?<br />Sign up for Cabana</>}
          </h1>
          <div className={CN('vr-signup-actions', {'fade-out': signedUp})}>
            <input type="email" placeholder="Enter your email" onChange={e => setEmail(e.target.value)} onKeyDown={signupOnEnter}></input>
            <Button filled disabled={email === ''} text="Sign me up" onClick={signup}/>
          </div>
          </div>

        <div className="vr-finished-section">
        <h1 className="vr-finished-title">
          Would you like to experience another
          <br />
          micro-vacation?
        </h1>
        <div className="vr-next-actions">
          <Button text="No, thanks" outlined onClick={goHome} />
          <Button text="Yes, let's go!" outlined={!signedUp && device !== Device.HEADSET} filled={signedUp || device === Device.HEADSET} onClick={goAgain} />
        </div>
        </div>
      </div>
    </>
  )
}

export default Finished;