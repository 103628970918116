const FaqText = () => {
  return (
    <ul>
            <li className="faq-page-list-item-title">
              What should I expect when I use the Cabana pod?
            </li>
            <p className="faq-page-list-item-text">
              The Cabana pod offers a private, safe space for you to mentally reset during your workday. Use the provided tablet to select a setting and guided exercise, and, if available, a VR headset to immerse yourself in a virtual oasis. Plan to spend about 5-10 minutes in the pod. Please share feedback when you’re done!
            </p>
            <li className="faq-page-list-item-title">
              How do I reserve time in the Cabana pod?
            </li>
            <p className="faq-page-list-item-text">
              No reservation is needed. If the pod is empty, it’s ready for use.
            </p>
            <li className="faq-page-list-item-title">
              Is there a limit to how much time I can spend in the Cabana pod?
            </li>
            <p className="faq-page-list-item-text">
              There’s no strict time limit, but please be considerate of others who may want to use it. Exercises typically last 4-10 minutes.
            </p>
            <li className="faq-page-list-item-title">
              Do I have to use the headset/tablet in the pod? Can I meditate on my own?
            </li>
            <p className="faq-page-list-item-text">
              Using the tablet or VR headset enhances the experience, but you’re welcome to meditate on your own. Just keep the pod’s intended purpose in mind.
            </p>
            <li className="faq-page-list-item-title">
              Are there any risks to using the VR headset?
            </li>
            <p className="faq-page-list-item-text">
              Some general risks apply to VR headsets. Review our disclaimer at https://www.yourcabana.com/vr-headset-disclaimer.
            </p>
            <li className="faq-page-list-item-title">
              How often is the Cabana pod sanitized?
            </li>
            <p className="faq-page-list-item-text">
              Pods are thoroughly cleaned regularly. If additional cleaning is needed, please email support@yourcabana.com.
            </p>
            <li className="faq-page-list-item-title">
              Who do I contact if something is wrong with the Cabana pod?
            </li>
            <p className="faq-page-list-item-text">
              For any issues or suggestions, email us at support@yourcabana.com.
            </p>
            <li className="faq-page-list-item-title">
              Where do I go for more wellness content?
            </li>
            <p className="faq-page-list-item-text">
              Visit https://yourcabana.com for live group sessions and a library of on-demand content designed to support self-improvement in mental wellness.
            </p>
    </ul>
  );
};

export default FaqText;
